import {defineStore} from 'pinia'
import {useAuthStore} from '@/store/auth'
import {useToast} from "vue-toastification";
import Echo from '@ably/laravel-echo';
import * as Ably from 'ably';
import {useEventBus} from '@vueuse/core'

export const useWebsockets = defineStore('websockets', {
    // convert to a function
    state: () => ({
        channel: null,
        members: {},
    }),

    getters: {
        userActive: (state) => {
            return (userId) => {


            }
        },
    },

    actions: {
        async init(mode = 'default', boardToken) {
            const config = useRuntimeConfig()
            window.Ably = Ably;
            window.Echo = new Echo({
                broadcaster: 'ably',
                authEndpoint: "/app/spa/broadcasting/auth",
            })

            // Register a callback for listing to connection state change
            // window.Echo.connector.ably.connection.on((stateChange) => {
            //     console.log("LOGGER:: Connection event :: ", stateChange);
            //     if (stateChange.current === 'disconnected' && stateChange.reason?.code === 40142) { // key/token status expired
            //         console.log("LOGGER:: Connection token expired https://help.ably.io/error/40142");
            //     }
            // });

            if (mode === 'default') {
                this.joinTestPublic()
                this.joinOrganisationPresence()
                this.joinUserPrivate()
            }
            if (mode === 'dashboardBoard') {
                this.joinDashboardBoardPublic(boardToken)
            }
        },

        joinDashboardBoardPublic(boardToken) {
            // public channel
            window.Echo
                .channel('dashboardBoard.' + boardToken)
                .subscribed(() => useEventBus('successfulDashboardSubscription').emit())
                .listen('DashboardNewEventData', (event) => useEventBus('dashboardNewEventData').emit(event))
                .listen('DashboardNewGoalData', (event) => useEventBus('dashboardNewGoalData').emit(event))
                .listen('DashboardUpdated', (event) => useEventBus('dashboardUpdated').emit(event))
        },

        leaveDashboardBoardPublic(boardToken) {
            window.Echo
                .leave('dashboardBoard.' + boardToken)
        },

        joinTestPublic() {
            // public channel
            window.Echo.channel('test')
                .listen('Test', (e) => {
                    console.log("TEST", e);
                });
        },

        joinOrganisationPresence() {
            // presence channel
            this.channel = window.Echo.join('organisation.' + useAuthStore().userData.organisation_id)
                .here(e => {
                    //this.members = {...this.channel.subscription.members.members}
                })
                .joining(e => {
                    //this.members = {...this.channel.subscription.members.members}
                })
                .leaving(e => {
                    //this.members = {...this.channel.subscription.members.members}
                })
                .error(error => {
                    if (error && error.code === 40142) { // ably token expired
                        console.error("ABLY ERROR:", error);
                    }
                })
                .listen('Trigger', (event) => useEventBus('organisationTrigger').emit(event))
                .listen('RegistrationConfirm', (event) => useEventBus('registrationConfirm').emit(event))
                .listen('VolumeChanged', (event) => useEventBus('volumeChange').emit(event))
                .listen('DeviceStatusChanged', (event) => useEventBus('deviceStatus').emit(event))
                .listen('DevicePrepared', (event) => useEventBus('devicePrepare').emit(event))
                .listen('EventClaimed', (event) => useEventBus('eventClaimed').emit(event))
                .listen('SubscriptionUpdated', (event) => useEventBus('subscriptionUpdated').emit(event))
        },

        joinUserPrivate() {
            window.Echo.private('App.Models.User.' + useAuthStore().userData.id)
                .listen('Trigger', (event) => useEventBus('trigger').emit(event))
                .notification((notification) => {
                    if (notification.type === 'App\\Notifications\\ClaimEventNotification') {
                        useEventBus('claimEvent').emit(notification)
                    }
                    if (notification.type === 'App\\Notifications\\NewFirmwareNotification') {
                        useEventBus('firmwareUpdate').emit(notification)
                    }
                    if (notification.type === 'App\\Notifications\\FirmwareUpdateResultNotification') {
                        useEventBus('firmwareUpdateResult').emit(notification)
                    }
                })

                .error(error => {
                    if (error && error.code === 40142) { // ably token expired
                        console.error("ABLY ERROR:", error);
                    }
                })
        }
        ,
    }
})